import showCategoryRoute from "./show.category.route";

export default {
  routes: [
    {
      path: "/pricebook",
      name: "pricebook-index",
      component: () => import("./WrapperPricebook.vue"),
      meta: {
        title: "library.pricebook",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "pricebook",
          component: () => import("./PricebookList.vue"),
          meta: {
            permissions: ["can_access_price_book", "can_list_category"],
          },
        },
        {
          path: "create",
          name: "create-pricebook",
          component: () => import("./CreatePricebook.vue"),
          meta: {
            permissions: ["can_create_category"],
          },
        },
        {
          path: "edit/:id",
          name: "edit-pricebook",
          component: () => import("./CreatePricebook.vue"),
          meta: {
            isEdit: true,
            permissions: ["can_update_category"],
          },
        },
        {
          path: "",
          name: "show-category-index",
          component: () => import("./IndexShowCategory.vue"),
          redirect: { name: "show-category" },
          children: [...showCategoryRoute.routes],
        },
      ],
    },
  ],
};
