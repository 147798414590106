export default {
  routes: [
    {
      path: "/marketplace",
      name: "marketplace-index",
      component: () => import("./MarketPlaceIndex.vue"),
      meta: {
        title: "marketplace",
        roles: [""],
        permissions: ["can_access_marketplace"],
      },
      children: [
        {
          path: "",
          name: "marketplace",
          component: () => import("./WrapperMarketplace.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_marketplace"],
          },
        },
        {
          path: "create",
          name: "create-marketplace",
          component: () => import("./CreateMarketplace.vue"),
          meta: {
            permissions: ["can_request_service_provider"],
            roles: [""],
          },
        },
        {
          path: "edit/:id",
          name: "edit-marketplace",
          component: () => import("./CreateMarketplace.vue"),
          meta: {
            permissions: ["can_update_form_marketplace"],
            roles: [""],
            isEdit: true,
          },
        },
      ],
    },
  ],
};
