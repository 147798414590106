import accountStatementRoute from "./AccountStatement/accountStatement.route";
import budgetingRoute from "./Budgeting/budgeting.route";
import expensesRoute from "./Expenses/expenses.route";
import incomeRoute from "./Income/income.route";
import reportingRoute from "./Reporting/reporting.route";

export default {
  routes: [
    {
      path: "/accounting",
      name: "accounting",
      component: () => import("./WrapperAccounting.vue"),
      meta: {
        title: "accounting",
        roles: [""],
        permissions: ["can_access_accounting"],
      },
      redirect: { name: "accounting-dashboard" },
      children: [
        {
          path: "dashboard",
          name: "accounting-dashboard",
          component: () =>
            import("./AccountingDashboard/AccountingDashboard.vue"),
          meta: {
            permissions: ["can_access_accounting_dashboard"],
            roles: [""],
          },
        },
        {
          path: "expenses",
          name: "accounting-expenses",
          component: () => import("./Expenses/WrapperExpenses.vue"),
          meta: {
            permissions: ["can_access_accounting_expenses"],
            roles: [""],
          },
          redirect: { name: "operation-expenses" },
          children: [...expensesRoute.routes],
        },
        {
          path: "income",
          name: "accounting-income",
          component: () => import("./Income/WrapperIncome.vue"),
          meta: {
            permissions: ["can_access_accounting_income"],
            roles: [""],
          },
          redirect: { name: "property-income" },
          children: [...incomeRoute.routes],
        },
        {
          path: "budgeting",
          name: "accounting-budgeting",
          component: () => import("./Budgeting/WrapperBudgeting.vue"),
          meta: {
            permissions: ["can_access_accounting_budgeting"],
            roles: [""],
          },
          redirect: { name: "budget-timeframe" },
          children: [...budgetingRoute.routes],
        },
        {
          path: "reporting",
          name: "accounting-reports",
          component: () => import("./Reporting/Reporting.vue"),
          meta: {
            permissions: ["can_access_accounting_reporting"],
            roles: [""],
          },
          redirect: { name: "pl-statements" },
          children: [...reportingRoute.routes],
        },
        {
          path: "statement",
          name: "accounting-statement",
          component: () =>
            import("./AccountStatement/WrapperAccountStatement.vue"),
          meta: {
            permissions: ["can_access_accounting_account_statement"],
            roles: [""],
          },
          redirect: { name: "statement-list" },
          children: [...accountStatementRoute.routes],
        },
        {
          path: "invoice",
          name: "invoice",
          component: () => import("./InvoiceTemplate/WrapperInvoices.vue"),
          redirect: { name: "invoice-vendor" },
          meta: {
            permissions: ["can_access_accounting_resident_invoices"],
            roles: [""],
          },
          children: [
            {
              path: "invoice-vendor",
              name: "invoice-vendor",
              component: () => import("./InvoiceTemplate/VendorInvoice.vue"),
              meta: {
                permissions: ["can_access_accounting_resident_invoices"],
                roles: [""],
              },
            },
            {
              path: "resident",
              name: "resident-invoice",
              component: () =>
                import("./InvoiceTemplate/PropertyInvoice/PropertyInvoice.vue"),
              meta: {
                permissions: ["can_access_accounting_resident_invoices"],
                roles: [""],
              },
            },
            {
              path: "service",
              name: "service-invoice",
              component: () => import("./InvoiceTemplate/ServiceInvoice.vue"),
              meta: {
                permissions: ["can_access_accounting_resident_invoices"],
                roles: [""],
              },
            },
          ],
        },
      ],
    },
    {
      path: "create-invoice-template",
      name: "create-invoice-template",
      component: () => import("./InvoiceTemplate/CreateInvoiceTemplate.vue"),
      meta: {
        permissions: ["can_create_accounting_resident_template_invoices"],
        roles: [""],
      },
    },
  ],
};
