export default {
  routes: [
    {
      path: "/users",
      component: () => import("./index.vue"),
      meta: {
        title: "user_management.users",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "users",
          component: () => import("./WrapperUsers.vue"),
          redirect: { name: "all_users" },
          children: [
            {
              path: "all",
              name: "all_users",
              component: () => import("./UsersList.vue"),
              meta: {
                roles: [""],
                permissions: ["can_access_users", "can_list_user"],
              },
            },
            {
              path: "roles",
              name: "user_roles",
              component: () => import("./UserRoles.vue"),
              meta: {
                roles: [""],
                permissions: ["can_access_roles", "can_list_role"],
              },
            },
            {
              path: "departments",
              name: "departments",
              component: () => import("./Departments.vue"),
              meta: {
                roles: [""],
                permissions: ["can_access_departments", "can_list_departments"],
              },
            },
          ],
        },
        {
          path: "create",
          name: "create-user",
          component: () => import("./NewUser.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_user"],
          },
        },
        {
          path: ":id/edit",
          name: "edit-user",
          props: true,
          component: () => import("./NewUser.vue"),
          meta: {
            roles: [""],
            isEdit: true,
            permissions: ["can_update_user"],
          },
        },
        {
          path: ":id",
          component: () => import("./details/UserDetailsWrapper.vue"),
          name: "details-wrapper",
          redirect: "details",
          props: true,
          meta: {
            roles: [""],
            permissions: ["can_view_user"],
          },
          children: [
            {
              path: "details",
              name: "user-details",
              component: () => import("./details/UserInfo.vue"),
              meta: {
                permissions: ["can_view_user"],
                roles: [""],
              },
            },
            {
              path: "users-logs",
              name: "users-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              // component: () => import("./details/ActivityLog.vue"),
              meta: {
                permissions: ["can_view_user"],
                roles: [],
              },
            },
          ],
        },
      ],
    },
  ],
};
