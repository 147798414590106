import showCorrectiveRoute from "./ShowCorrective/show.corrective.route";

export default {
  routes: [
    {
      path: "/corrective",
      name: "index",
      component: () => import("./CorrectiveIndex"),
      redirect: { name: "corrective" },
      meta: {
        title: "work_orders.corrective",
        roles: [""],
        permissions: [
          "can_access_corrective_work_orders",
          "can_list_corrective_work_orders",
        ],
      },
      children: [
        {
          path: "",
          name: "corrective",
          component: () => import("./WrapperCorrective.vue"),
          meta: {
            permissions: [
              "can_access_corrective_work_orders",
              "can_list_corrective_work_orders",
            ],
            roles: [""],
          },
          redirect: { name: "corrective-list-view" },
          children: [
            {
              path: "list",
              name: "corrective-list-view",
              component: () => import("./ListView/CorrectiveList.vue"),
              meta: {
                permissions: [
                  "can_access_corrective_work_orders",
                  "can_list_corrective_work_orders",
                ],
                roles: [""],
              },
            },
            {
              path: "calendar",
              name: "corrective-calendar-view",
              component: () => import("./CalendarView/CorrectiveCalendar.vue"),
              meta: {
                permissions: [
                  "can_access_corrective_work_orders",
                  "can_list_corrective_work_orders",
                ],
                roles: [""],
              },
            },

            {
              path: "board",
              name: "corrective-board-view",
              component: () => import("./BoardView/CorrectiveBoard.vue"),
              meta: {
                roles: [""],
                permissions: [
                  "can_access_corrective_work_orders",
                  "can_list_corrective_work_orders",
                ],
              },
            },
          ],
        },
        {
          path: ":id",
          name: "wrapper-show-corrective",
          component: () => import("./ShowCorrective/WrapperShowCorrective.vue"),
          props: true,
          meta: {
            roles: [""],
            permissions: ["can_view_corrective_work_orders"],
          },
          redirect: { name: "corrective-details" },
          children: [...showCorrectiveRoute.routes],
        },
        {
          path: "create",
          name: "corrective-create",
          component: () => import("./CreateCorrective/CreateCorrective.vue"),
          meta: {
            roles: [""],
            isEdit: false,
            permissions: ["can_create_corrective_work_orders"],
          },
        },
        {
          path: "edit/:id",
          name: "corrective-edit",
          component: () => import("./CreateCorrective/CreateCorrective.vue"),
          meta: {
            roles: [""],
            isEdit: true,
            permissions: ["can_update_corrective_work_orders"],
          },
        },
        {
          path: ":id/create-tasks",
          name: "create-tasks",
          component: () => import("./CreateTask.vue"),
          meta: {
            permissions: ["can_add_task_corrective_work_orders"],
            roles: [""],
          },
        },
        {
          path: ":id/request-payment",
          name: "request-payment",
          component: () => import("./RequestPayment.vue"),
          props: true,
          meta: {
            permissions: ["can_view_corrective_work_orders"],
            roles: [""],
          },
        },
      ],
    },
  ],
};
