export default {
  routes: [
    {
      path: "pl-statements",
      name: "pl-statements",
      component: () => import("./PLStatements.vue"),
      meta: {
        roles: [""],
      },
    },
    {
      path: "balance-sheet",
      name: "balance-sheet",
      component: () => import("./BalanceSheet.vue"),
      meta: {
        roles: [""],
      },
    },
    {
      path: "accounts-receivable",
      name: "accounts-receivable",
      component: () => import("./AccountsPayable.vue"),
      meta: {
        roles: [""],
      },
    },
    {
      path: "accounts-payable",
      name: "accounts-payable",
      component: () => import("./AccountsPayable.vue"),
      meta: {
        roles: [""],
      },
    },
    {
      path: "revenue-customer",
      name: "revenue-customer",
      component: () => import("./RevenueCustomer.vue"),
      meta: {
        roles: [""],
      },
    },
  ],
};
