import showPpmRoute from "./ShowPPM/show.ppm.route";

export default {
  routes: [
    {
      path: "/ppm",
      name: "ppm-index",
      component: () => import("./PPMIndex.vue"),
      meta: {
        title: "work_orders.ppm",
        roles: [""],
        permissions: ["can_access_ppm_work_orders", "can_list_ppm_work_orders"],
      },
      children: [
        {
          path: "",
          name: "ppm",
          component: () => import("./WrapperPPM.vue"),
          meta: {
            roles: [""],
            permissions: [
              "can_access_ppm_work_orders",
              "can_list_ppm_work_orders",
            ],
          },
          redirect: { name: "ppm-list-view" },
          children: [
            {
              path: "list",
              name: "ppm-list-view",
              component: () => import("./ListView/PPMList.vue"),
              meta: {
                roles: [""],
                permissions: [
                  "can_access_ppm_work_orders",
                  "can_list_ppm_work_orders",
                ],
              },
            },
            {
              path: "calendar",
              name: "ppm-calendar-view",
              component: () => import("./CalendarView/PPMCalendar.vue"),
              meta: {
                roles: [""],
                permissions: [
                  "can_access_ppm_work_orders",
                  "can_list_ppm_work_orders",
                ],
              },
            },
          ],
        },
        {
          path: "create",
          name: "ppm-create",
          component: () => import("./CreatePPM/CreatePPM.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_ppm_work_orders"],
          },
        },
        {
          path: "edit/:id",
          name: "ppm-edit",
          component: () => import("./CreatePPM/EditPPM.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_ppm_work_orders"],
          },
        },
        {
          path: ":id",
          name: "wrapper-show-ppm",
          component: () =>
            import(
              "../CorrectiveOrders/ShowCorrective/WrapperShowCorrective.vue"
            ),
          props: true,
          meta: {
            roles: [""],
            permissions: ["can_view_ppm_work_orders"],
          },
          redirect: { name: "ppm-details" },
          children: [...showPpmRoute.routes],
        },
      ],
    },
  ],
};
